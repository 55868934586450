import React from "react"

import waltz_krem_kakao from "../../images/products/waltz-krem-kakao.jpg"
import waltz_krem_mlecni from "../../images/products/waltz-krem-mlecni.jpg"
import waltz_krem_mesavina from "../../images/products/waltz-krem-mesavina.jpg"
import sweet_krem_kakao from "../../images/products/sweet-krem-kakao.jpg"
import sweet_krem_mesavina from "../../images/products/sweet-krem-mesavina.jpg"

class WaltzCream extends React.Component {
  render() {
    return (
      <section id="proizvodi" className="hero products-page default-page">
        <div className="container padding-0 margin-0">
          <div className="columns margin-0 main">
            <div className="column is-4 static-part is-left padding-0">
              <div className="content">
                <div className="title">WALTZ CREAM</div>
                <span className="zigzag"></span>
                <p className="text">
                  Starting the day with your family is priceless. Glass of milk,
                  a slice of bread and sweet spread and the day may begin. All
                  the magic of that taste fits into just one bucket. Carefully
                  designed for our customers. You'll see the difference.
                </p>
              </div>
            </div>
            {/*STATIC PART END */}
            <div className="column is-8 content-part is-right padding-0">
              <div className="columns is-multiline inner margin-0">
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_krem_kakao} alt="Waltz krem kakao" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_krem_mlecni} alt="Waltz krem mlečni" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={waltz_krem_mesavina} alt="Waltz krem mešavina" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={sweet_krem_kakao} alt="Sweet krem kakao" />
                </div>
                <div className="column is-6 single-product padding-0">
                  <img src={sweet_krem_mesavina} alt="Sweet krem mešavina" />
                </div>
              </div>
            </div>
            {/* PRODUCTS PART END */}
          </div>
          {/* COLUMNS END */}
        </div>
        {/* CONTAINER END */}
      </section> /* PRODUCTS PAGE SECTION END */
    )
  }
}

export default WaltzCream
